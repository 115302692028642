import React from 'react'

const Contacts = () => {
  return (
    <div className="secondary col-md-4">
                 <aside className="info aside section">
                    <div className="section-inner">
                        
                        <div className="content">
                            <ul className="list-unstyled">
								<li><i className="fa fa-phone"></i><a href="tel:+79095909831">8-909-590-98-31</a></li>
                                <li><i className="fa fa-envelope-o"></i><a href="mailto:mail@pnevmoprivod.ru">mail@pnevmoprivod.ru</a></li>
                                <li><i className="fa fa-map-marker"></i>195197, Санкт-Петербург, Полюстровский проспект, дом 43</li>
                            </ul>
                        </div>  
                    </div>
                </aside>
    
    </div>
  )
}

export default Contacts


