import SEO from '../SEO'
import About from '../About'
import CatalogList from '../CatalogList'
import pneumoCat from '../../data/pneumocat.json'
import electroCat from '../../data/electrocat.json'
import about from '../../data/about.json'
const Main = () => {
  return (
    <>
    <SEO title="Pnevmologic | Пневмооборудование | Электрооборудование" description="Pnevmologic, пневмоцилиндры, клапаны, фитинги, электродвигатели, редукторы, блоки управления электродвигателями" />
    <About aboutContent={ about } />
    <CatalogList catalogName="Пневмооборудование" catalogCards={ pneumoCat } />
    <CatalogList catalogName="Электрооборудование" catalogCards={ electroCat } />
    </>
  )
}

export default Main
