import SEO from '../SEO'
import CatalogList from '../CatalogList'
import reductorCat from '../../data/el/reductor.json'
const Reductor = () => {
  return (
    <>
    <SEO title="Pnevmologic | мотор-редукторы" description="Pnevmologic, электрооборудование, мотор-редукторы реверсивные, с тормозом и регулировкой скорости" />
    <CatalogList catalogName="Мотор-редукторы" catalogCards={ reductorCat } />
    </>
  )
}

export default Reductor
