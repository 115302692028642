import SEO from '../SEO'
import CatalogList from '../CatalogList'
import brushlessCat from '../../data/el/brushless.json'
const BrushLess = () => {
  return (
    <>
    <SEO title="Pnevmologic | бесколлекторные двигатели" description="Pnevmologic, электрооборудование, бесколлекторные двигатели, вентильные двигатели" />
    <CatalogList catalogName="Бесколлекторные двигатели" catalogCards={ brushlessCat } />
    </>
  )
}

export default BrushLess