import SEO from '../SEO'
import CatalogList from '../CatalogList'
import actuatorCat from '../../data/el/actuator.json'
const Actuator = () => {
  return (
    <>
    <SEO title="Pnevmologic | линейные актуаторы" description="Pnevmologic, электрооборудование, линейные актуаторы" />
    <CatalogList catalogName="Линейные актуаторы" catalogCards={ actuatorCat } />
    </>
  )
}

export default Actuator