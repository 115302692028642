import { TfiEmail } from 'react-icons/tfi'

const BtnTop = () => {
    const btnAlert = () => {
        alert(this.state)
    }
  return (
    <a className='btn btn-cta-primary' href='mailto:mail@pnevmoprivod.ru'>
      <TfiEmail className='me-2' size={ '1.4em' }></TfiEmail>
      <span className='align-middle'>Сделать заказ</span>
    </a>
  )
}

export default BtnTop
