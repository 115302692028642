import React from 'react'
import SEO from '../SEO'
import CatalogList from '../CatalogList'
import fitingCat from '../../data/pn/fiting.json'
const Fiting = () => {
  return (
    <>
    <SEO title="Pnevmologic | фитинги" description="Pnevmologic, пневмооборудование, фитинги прямые, угловые, тройники" />
    <CatalogList catalogName="Фитинги" catalogCards={ fitingCat } />
    </>
  )
}

export default Fiting
