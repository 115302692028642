import { Helmet } from 'react-helmet';
const SEO = ({ description, title }) => {

    return (
        <Helmet>
        <title>{title}</title>  
        <meta name="description" content={description} />  
        <meta name="theme-color" content="#E6E6FA" />  
        </Helmet>
    );
}
export default SEO