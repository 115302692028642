import { Link } from "react-router-dom"
import PList from "./html/PList"

const CatalogCard = ({ catCard, index }) => {
    const image = catCard.image
    const name = catCard.name
    const content = catCard.content
    const url = catCard.url
  return (
    <div className="item row" key={index}>
        <div className="col-md-4">
            <Link to={ url }><img className="img-fluid project-image" src={ image } alt={ name } data-nosnipet /></Link>
        </div>
        <div className="col-md-8">
            <h3 className="title"><Link className="img-fluid project-image" to={ url }>{ name }</Link></h3>
            <PList plist={ content }></PList>
        </div>
    </div>
  )
}

export default CatalogCard
