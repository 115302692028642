import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Contacts from './components/Contacts';
import Main from './components/pages/Main';
import Cilinder from './components/pages/Cilinder';
import Klapan from './components/pages/Klapan';
import Valve from './components/pages/Valve';
import Air from './components/pages/Air';
import Fiting from './components/pages/Fiting';
import ScrollToTop from './components/ScrollToTop';
import Actuator from './components/pages/Actuator';
import BrushLess from './components/pages/BrushLess';
import Step from './components/pages/Step';
import Reductor from './components/pages/Reductor';
import StepDriver from './components/pages/StepDriver';
import BrushLessDriver from './components/pages/BrushLessDriver';
import ReductorDriver from './components/pages/ReductorDriver';



const App = () => {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <Header ></Header>
    <div className="container sections-wrapper">
      <div className="row">
      <div className="col-md-8">
    
    <Routes>
    <Route exact path='/' element={<Main />  }>
    </Route>
    <Route exact path='/pn/cilinder' element={<Cilinder /> }></Route>
    <Route exact path='/pn/klapan' element={<Klapan /> }></Route>
    <Route exact path='/pn/valve' element={<Valve /> }></Route>
    <Route exact path='/pn/air' element={<Air /> }></Route>
    <Route exact path='/pn/fiting' element={<Fiting /> }></Route>
    <Route exact path='/el/actuator' element={<Actuator /> }></Route>
    <Route exact path='/el/brushless' element={<BrushLess /> }></Route>
    <Route exact path='/el/step' element={<Step /> }></Route>
    <Route exact path='/el/reductor' element={<Reductor /> }></Route>
    <Route exact path='/el/stepdriver' element={<StepDriver /> }></Route>
    <Route exact path='/el/brushlessdriver' element={<BrushLessDriver /> }></Route>
    <Route exact path='/el/reductordriver' element={<ReductorDriver /> }></Route>
    <Route path='*' element={<h1>NOT FOUND 404!</h1>}></Route>
    </Routes>
    
    </div>
    <Contacts />
    </div>
    </div>
    </BrowserRouter>
  )
}

export default App;
