import React from 'react'
import PList from './html/PList'
const About = ({ aboutContent }) => {
    const content = aboutContent.content
  return (
    <div className="section">
    <div className="section-inner latest">
      <h2 className="heading">О компании</h2>
   <div className="content">
    <PList plist={ content } />
   </div>
   </div>
  </div>
  )
}

export default About
