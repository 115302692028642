import CatalogCard from "./CatalogCard"
const CatalogList = ({ catalogName, catalogCards }) => {
  const catalogList = catalogCards.map((item, index) => <CatalogCard key={ index } catCard={ item }></CatalogCard>)
    return (
    <div className="section">
      <div className="section-inner latest">
        <h2 className="heading">{ catalogName }</h2>
     { catalogList }
     </div>
    </div>
  )
}

export default CatalogList
