import React from 'react'
import SEO from '../SEO'
import klapanCat from '../../data/pn/klapan.json'
import CatalogList from '../CatalogList'
const Klapan = () => {
  return (
    <>
    <SEO title="Pnevmologic | пневмоклапаны" description="Pnevmologic, пневмооборудование, пневмоклапаны" />
    <CatalogList catalogName="Пневмоклапаны" catalogCards={ klapanCat } />
    </>
  )
}

export default Klapan
