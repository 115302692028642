import React from 'react'
import SEO from '../SEO'
import cilinderCat from '../../data/pn/cilinder.json'
import CatalogList from '../CatalogList'
const Cilinder = () => {
  return (
    <>
    <SEO title="Pnevmologic | пневмоцилиндры" description="Pnevmologic, пневмооборудование, пневматические цилиндры" />
    <CatalogList catalogName="Пневмоцилиндры" catalogCards={ cilinderCat } />
    </>
  )
}

export default Cilinder
