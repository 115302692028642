import React from 'react'
import SEO from '../SEO'
import CatalogList from '../CatalogList'
import airCat from '../../data/pn/air.json'
const Air = () => {
  return (
    <>
    <SEO title="Pnevmologic | элементы подготовки воздуха" description="Pnevmologic, пневмооборудование, элементы подготовки сжатого воздуха, фильтры, блоки управления, лубрикаты (маслораспылители)" />
    <CatalogList catalogName="Элементы подготовки сжатого воздуха" catalogCards={ airCat } />
    </>
  )
}

export default Air
