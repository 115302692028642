import SEO from '../SEO'
import CatalogList from '../CatalogList'
import stepdriverCat from '../../data/el/stepdriver.json'
const StepDriver = () => {
  return (
    <>
    <SEO title="Pnevmologic | блоки управления ШД" description="Pnevmologic, электрооборудование, контроллеры и драйверы для управления шаговыми двигателями" />
    <CatalogList catalogName="Блоки управления ШД" catalogCards={ stepdriverCat } />
    </>
  )
}

export default StepDriver