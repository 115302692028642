import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/lato/400.css'
import 'bootstrap/dist/css/bootstrap.css'
import './css/styles.css'
import './assets/plugins/font-awesome/css/font-awesome.css'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />);
