import SEO from '../SEO'
import CatalogList from '../CatalogList'
import reductordriverCat from '../../data/el/reductordriver.json'
const ReductorDriver = () => {
  return (
    <>
    <SEO title="Pnevmologic | блоки управления мотор-редукторами" description="Pnevmologic, электрооборудование, контроллеры и драйверы для управления мотор-редукторами и двигателями постоянного тока" />
    <CatalogList catalogName="Блоки управления мотор-редукторами" catalogCards={ reductordriverCat } />
    </>
  )
}

export default ReductorDriver
