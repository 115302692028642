import SEO from '../SEO'
import CatalogList from '../CatalogList'
import brushlessdriverCat from '../../data/el/brushlessdriver.json'
const BrushLessDriver = () => {
  return (
    <>
    <SEO title="Pnevmologic | блоки управления БД" description="Pnevmologic, электрооборудование, блоки управления бесколлеторными двигателями, драйверы вентильных двигателей" />
    <CatalogList catalogName="Блоки управления бесколлекторными двигателями" catalogCards={ brushlessdriverCat } />
    </>
  )
}

export default BrushLessDriver
