import React from 'react'
import SEO from '../SEO'
import CatalogList from '../CatalogList'
import valveCat from '../../data/pn/valve.json'
const Valve = () => {
  return (
    <>
    <SEO title="Pnevmologic | пневмораспределители" description="Pnevmologic, пневмооборудование, пневмораспределители" />
    <CatalogList catalogName="Пневмораспределители" catalogCards={ valveCat } />
    </>
  )
}

export default Valve
