import SEO from '../SEO'
import CatalogList from '../CatalogList'
import stepCat from '../../data/el/step.json'
const Step = () => {
  return (
    <>
    <SEO title="Pnevmologic | шаговые двигатели" description="Pnevmologic, электрооборудование, шаговые двигатели" />
    <CatalogList catalogName="Шаговые двигатели" catalogCards={ stepCat } />
    </>
  )
}

export default Step