const PList = ({ plist }) => {
    const temp = plist.map((item, index) => <p key={index} data-nosnipet>{item}</p>)
        
    return (
        <>
        { temp }
        </>
    )
}

export default PList
