import { Link } from "react-router-dom";
import logo from '../assets/images/profile.png'
import BtnTop from "./BtnTop";

const Header = () => {
    return (
    <header className='container-fluid header'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-2'>
                <Link to='/'>
                    <img className='img-fluid' src={ logo } alt='PNEVMOLOGIC' />
                </Link>
            </div>
            <div className='col-md-8'>
                <h1 className='name'>PNEVMOLOGIC</h1>
                <h2 className='desc'>Пневмооборудование и компоненты пневмосистем</h2>
            </div>
            <div className='col-md-2'>
                 <BtnTop></BtnTop>      
            </div>
            </div>
        </div>
        
    </header>
    );
}
export default Header;